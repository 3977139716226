import React from 'react'
import styled from 'styled-components';
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { CallToAction, GetStarted } from '../../components/CallToAction'
import { Quote } from '../../components/Quote'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle2 } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';
import { CenteredImg, WidthImg } from '../../common/Style';
import { VideoFrame } from '../../components/VideoFrame';

import { salesLeaders } from '../../common/Util';

import SfOnLaptop from '../images/sfOnLaptop.png'
import SfNotes from '../images/sfNotes.svg'
import TeamDashHalf from '../images/teamDashHalf.svg'

import OldWay from '../images/oldWay.svg';
import ActionEvent from '../images/actionEvent.svg';
import SalesforceDashboard from '../images/salesforceDashboard.png'
import IntegrationsGrid from '../images/integrationsGrid.svg'



const SolutionsPage = () => (
  <Default>
    <Helmet>
      <title>Truly | For Data Driven Sales Leaders</title>
    </Helmet>

    <WrapperBackground
      color={colors.trulyDark}
      background='wave'
    >
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width="1">
          <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
            What Truly Can Do For You
          </PageTitle2>
          <RoleSelector>
            <a href="/solutions/for-revenue-ops">
              <Role>Revenue Operations</Role>
            </a>
            <a href="/solutions/for-analytics-teams">
              <Role>Analytics Teams</Role>
            </a>
            <a href="/solutions/for-sales-leaders">
              <ActiveRole>Sales Teams</ActiveRole>
            </a>
            <a href="/solutions/for-marketing">
              <Role>Marketing Teams</Role>
            </a>
          </RoleSelector>

          <br/><br/>
          
          <Title3 as="h2" color={colors.accentFog} bold mt="3rem" mb="1.5rem">
              Make Process Excellence Your Competitive Advantage
              </Title3>
              <Large as="p" color={colors.white} mb="1.5rem" textAlign="left">
               Process excellence has picked winners and losers in every industry/function for the past 30 years, even in commoditized industries (Toyota, Zara, Amazon).  The key to making this work is reliable data and scalable automation.  Truly is the first and only platform to make this possible for sales.
              <br/><br/><br/>
              <b><u>Truly Capture</u></b> product (email, voice, web meetings, sms) automatically capture more activity data in salesforce than any competing product (68% more emails than sales engagement tools like Outreach, 33% more calls than call center software like LiveOps, 6% more web meetings than conversation intelligence tools like Gong)
              <br/><br/><br/><br/>
              <b><u>Truly AI</u></b> enriches all of your activity data and delivering reportable, structured metadata in Salesforce.  Transcription makes your conversational content reportable and searchable in salesforce.  Conversation analytics shows you 'true talktime'.  Contact intelligence exposes the seniority and identity of the people you are talking to in web meetings.  AI call notes summarize what happens on calls
              <br/><br/><br/>
               <b><u>Sales Process Optimizer</u></b> allows you to automate all data entry (create contacts, progress opportunities, update dispositions, list competitors and more!).  Funnel Analytics automatically models your funnel to give you reports/insights into your sales performance.  Process optimizer allows you to test if any aspect of your sales process is being adhered to AND demonstrate how much it improves funnel conversion.  Revenue Process automation allows you to take processes that work and automate them for reps/managers, to ensure more consistent execution and getting hours of time back each week.
              <br/><br/><br/>
              <br/><br/><br/>
              </Large>

              <VideoFrameContainer>
                 <VideoFrame caption="Measure Process Adherence" background="Analytics" videoLink="https://player.vimeo.com/video/637984038?h=6244509ca9&amp;badge=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479" />
              </VideoFrameContainer>
          
        </Box>
      </Section>
    </WrapperBackground>

  </Default>
)

export default SolutionsPage

const RoleSelector = styled.div`
  justify-content: center;
  border: white 2px solid;
  border-radius: 32pt;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-align: center;
  flex-grow: 0;
  width: 500px;
  margin: 0 auto;
`;

const VideoFrameContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-align: center;
  justify-content: center;
`

const Role = styled.div`
  color: white;
  overflow: hidden;
  padding: 12px 16px;
  margin: 4px 4px;
  border-radius: 32pt;
  display: flex;
  flex-align: center;
  flex-grow: 1;
  justify-content: center;

  :hover{
    background-color: rgba(255,255,255,0.3);
    animation: background color 0.2s;
  }
`;

const ActiveRole = styled.div`
  color: black;
  font-weight: bold;
  border-radius: 32pt;
  background-color: white;
  overflow: hidden;
  padding: 12px 16px;
  margin: 4px 4px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;